import { useContractCall } from "@usedapp/core";
import { utils } from "ethers";
import keeperHelper from "@merofinance/protocol/build/contracts/TopUpKeeperHelper.json";
import { ScaledNumber } from "scaled-number";
import { TokenInfo } from "@usedapp/core/dist/esm/src/model/TokenInfo";

import useGlobals from "../app/hooks/use-globals";
import { useTokenInfo } from "./tokenViews";

export interface ExecutablePositionType {
  account: string;
  payer: string;
  protocol: string;
  actionToken: TokenInfo | undefined;
  depositToken: TokenInfo | undefined;
  depositTokenBalance: ScaledNumber;
  maxFee: ScaledNumber;
  priorityFee: ScaledNumber;
  repayDebt: boolean;
  singleTopUpAmount: ScaledNumber;
  threshold: ScaledNumber;
  totalTopUpAmount: ScaledNumber;
}

export const useExecutablePositions = (): any[] => {
  const globals = useGlobals();
  const [positions] = useContractCall({
    abi: new utils.Interface(keeperHelper.abi),
    address: globals.KEEPER_HELPER,
    method: "getExecutableTopups",
    args: [0, 10],
  }) ?? [[]];
  return positions;
};

function hexToString(hexx: string): string {
  const hex = hexx.toString();
  let str = "";
  for (let i = 0; i < hex.length; i += 2)
    str += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
  return str;
}

export const usePositionInfo = (position: any): ExecutablePositionType => {
  const actionToken = useTokenInfo(position.record.actionToken);
  const depositToken = useTokenInfo(position.record.depositToken);

  return {
    account: position.account,
    payer: position.payer,
    protocol: hexToString(position.protocol),
    actionToken,
    depositToken,
    depositTokenBalance: new ScaledNumber(
      position.record.depositTokenBalance,
      depositToken?.decimals || 18
    ),
    maxFee: new ScaledNumber(position.record.maxFee, 9),
    priorityFee: new ScaledNumber(position.record.priorityFee, 9),
    repayDebt: position.record.repayDebt,
    singleTopUpAmount: new ScaledNumber(
      position.record.singleTopUpAmount,
      actionToken?.decimals || 18
    ),
    threshold: new ScaledNumber(position.record.threshold, 18),
    totalTopUpAmount: new ScaledNumber(
      position.record.totalTopUpAmount,
      actionToken?.decimals || 18
    ),
  };
};
