import { useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { ScaledNumber } from "scaled-number";

import Button from "../../components/Button";
import ChangeConvexPool from "../../components/ChangeConvexPool";
import TextInputPopup from "../../components/TextInputPopup";
import { usePool, usePoolLpTokenAddress } from "../../contracts/poolViews";
import {
  useAddRewardToken,
  useSetImbalanceToleranceIn,
  useSetImbalanceToleranceOut,
} from "../../contracts/strategyFunctions";
import {
  useHarvest,
  usePrepareNewStrategy,
  useWithdrawAll,
} from "../../contracts/vaultFunctions";
import { Header1, Header2 } from "../../styles/Headers";

const StyledPoolPage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  font-size: 2.3rem;
  padding: 3rem;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 4rem;
`;

interface ColumnProps {
  right?: boolean;
}

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props: ColumnProps) =>
    props.right ? "flex-end" : "flex-start"};

  > div > button {
    margin-bottom: 2rem;
  }
`;

const Info = styled.div`
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 0.6rem;
`;

const PoolPage = () => {
  const { poolAddress } = useParams();
  const pool = usePool(poolAddress || "");
  const poolLpTokenAddress = usePoolLpTokenAddress(poolAddress || "");
  const { harvestState, harvest } = useHarvest(poolAddress || "");
  const { withdrawAllState, withdrawAll } = useWithdrawAll(poolAddress || "");
  const { prepareNewStrategyState, prepareNewStrategy } = usePrepareNewStrategy(
    poolAddress || ""
  );

  const [addingRewardToken, setAddingRewardToken] = useState(false);
  const [settingImbalanceToleranceIn, setSettingImbalanceToleranceIn] =
    useState(false);
  const [settingImbalanceToleranceOut, setSettingImbalanceToleranceOut] =
    useState(false);
  const [preparingNewStrategy, setPreparingNewStrategy] = useState(false);

  const { addRewardTokenState, addRewardToken } = useAddRewardToken(
    poolAddress || ""
  );
  const { setImbalanceToleranceInState, setImbalanceToleranceIn } =
    useSetImbalanceToleranceIn(poolAddress || "");
  const { setImbalanceToleranceOutState, setImbalanceToleranceOut } =
    useSetImbalanceToleranceOut(poolAddress || "");

  return (
    <StyledPoolPage>
      <Header1>{`${pool.name} pool`}</Header1>
      <Content>
        <Column>
          <Header2>pool</Header2>
          <Info>{`Address: ${pool.address}`}</Info>
          <Info>{`Name: ${pool.name}`}</Info>
          <Info>{`Underlying: ${pool.underlying?.symbol}`}</Info>
          <Info>{`LP Token: ${pool.lpToken?.symbol}`}</Info>
          <Info>{`LP Token Address: ${poolLpTokenAddress}`}</Info>
          <Info>{`Exchange Rate: ${pool.exchangeRate.toCryptoString()}`}</Info>
          <Info>{`Required Reserve Ratio: ${pool.requiredReserveRatio.toCryptoString()}`}</Info>
          <Info>{`Max Reserve Deviation Ratio: ${pool.maxReserveDeviationRatio.toCryptoString()}`}</Info>
          <Header2>vault</Header2>
          <Info>{`Address: ${pool.vault.address}`}</Info>
          <Info>{`Total Underlying: ${pool.vault.totalUnderlying.toString()} ${
            pool.underlying?.symbol
          }`}</Info>
          <Info>{`Target Allocation: ${pool.vault.targetAllocation.toCryptoString()}`}</Info>
          <Info>{`Bound: ${pool.vault.bound.toCryptoString()}`}</Info>
          <Header2>vault reserve</Header2>
          <Info>{`Address: ${pool.vault.vaultReserve.address}`}</Info>
          <Info>{`Is Vault Whitelisted: ${pool.vault.vaultReserve.isVaultWhitelisted}`}</Info>
          <Header2>strategy</Header2>
          <Info>{`Address: ${pool.vault.strategy.address}`}</Info>
          <Info>{`Vault Address: ${pool.vault.strategy.vaultAddress}`}</Info>
          <Info>{`Balance: ${pool.vault.strategy.balance.toCryptoString()} ${
            pool.underlying?.symbol
          }`}</Info>
          <Info>{`Curve Pool: ${pool.vault.strategy.curvePool}`}</Info>
          <Info>{`Curve LP Token: ${pool.vault.strategy.curvePoolLpToken?.symbol}`}</Info>
          <Info>{`Harvestable: ${pool.vault.strategy.harvestable.toCryptoString()} ${
            pool.underlying?.symbol
          }`}</Info>
          <Info>{`Imbalance Tolerance In: ${pool.vault.strategy.imbalanceToleranceIn.toCryptoString()}`}</Info>
          <Info>{`Imbalance Tolerance Out: ${pool.vault.strategy.imbalanceToleranceOut.toCryptoString()}`}</Info>
          <Info>{`Strategist: ${pool.vault.strategy.strategist}`}</Info>
        </Column>
        <Column right>
          <Header2>Actions</Header2>
          <Button
            loading={
              harvestState.status === "Mining" ||
              harvestState.status === "PendingSignature"
            }
            medium
            primary
            click={() => harvest()}
          >
            {`Harvest ${pool.vault.strategy.harvestable.toCryptoString()} ${
              pool.underlying?.symbol
            }`}
          </Button>
          <Button
            loading={
              withdrawAllState.status === "Mining" ||
              withdrawAllState.status === "PendingSignature"
            }
            medium
            primary
            click={() => withdrawAll()}
          >{`Withdraw all ${pool.vault.totalUnderlying.toCryptoString()} ${
            pool.underlying?.symbol
          } to Pool`}</Button>
          <ChangeConvexPool poolAddress={poolAddress || ""} />
          <Button medium primary click={() => setAddingRewardToken(true)}>
            Add Reward Token to Strategy
          </Button>
          <TextInputPopup
            header="Add Reward Token"
            loading={
              addRewardTokenState.status === "Mining" ||
              addRewardTokenState.status === "PendingSignature"
            }
            placeholder="Reward Token Address"
            show={addingRewardToken}
            close={() => setAddingRewardToken(false)}
            action={(v: string) => addRewardToken(v)}
          />
          <Button
            medium
            primary
            click={() => setSettingImbalanceToleranceIn(true)}
          >
            Set Imbalance Tolerance In
          </Button>
          <TextInputPopup
            header="Set Imbalance Tolerance In"
            loading={
              setImbalanceToleranceInState.status === "Mining" ||
              setImbalanceToleranceInState.status === "PendingSignature"
            }
            placeholder="Imbalance Tolerance In"
            show={settingImbalanceToleranceIn}
            close={() => setSettingImbalanceToleranceIn(false)}
            action={(v: string) => setImbalanceToleranceIn(v)}
          />
          <Button
            medium
            primary
            click={() => setSettingImbalanceToleranceOut(true)}
          >
            Set Imbalance Tolerance Out
          </Button>
          <TextInputPopup
            header="Set Imbalance Tolerance Out"
            loading={
              setImbalanceToleranceOutState.status === "Mining" ||
              setImbalanceToleranceOutState.status === "PendingSignature"
            }
            placeholder="Imbalance Tolerance Out"
            show={settingImbalanceToleranceOut}
            close={() => setSettingImbalanceToleranceOut(false)}
            action={(v: string) =>
              setImbalanceToleranceOut(ScaledNumber.fromUnscaled(v).value)
            }
          />
          <Button medium primary click={() => setPreparingNewStrategy(true)}>
            Prepare New Strategy
          </Button>
          <TextInputPopup
            header="Prepare New Strategy"
            loading={
              prepareNewStrategyState.status === "Mining" ||
              prepareNewStrategyState.status === "PendingSignature"
            }
            placeholder="New Strategy"
            show={preparingNewStrategy}
            close={() => setPreparingNewStrategy(false)}
            action={(v: string) => prepareNewStrategy(v)}
          />
        </Column>
      </Content>
    </StyledPoolPage>
  );
};

export default PoolPage;
