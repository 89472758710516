import { useContractCall } from "@usedapp/core";
import { utils } from "ethers";
import { TokenInfo } from "@usedapp/core/dist/esm/src/model/TokenInfo";
import addressProvider from "@merofinance/protocol/build/contracts/AddressProvider.json";
import liquidityPool from "@merofinance/protocol/build/contracts/LiquidityPool.json";
import { ScaledNumber } from "scaled-number";

import useGlobals from "../app/hooks/use-globals";
import { useVault, VaultType } from "./vaultViews";
import { useTokenInfo } from "./tokenViews";

export const usePoolAddresses = (): string[] => {
  const globals = useGlobals();
  const [pools] = useContractCall({
    abi: new utils.Interface(addressProvider.abi),
    address: globals.ADDRESS_PROVIDER,
    method: "allPools",
    args: [],
  }) ?? [[]];
  return pools;
};

export interface PoolType {
  address: string;
  lpToken: TokenInfo | undefined;
  name: string;
  underlying: TokenInfo | undefined;
  vault: VaultType;
  exchangeRate: ScaledNumber;
  requiredReserveRatio: ScaledNumber;
  maxReserveDeviationRatio: ScaledNumber;
}

export const usePoolLpTokenAddress = (address: string): string => {
  const [decimals] = useContractCall({
    abi: new utils.Interface(liquidityPool.abi),
    address,
    method: "getLpToken",
    args: [],
  }) ?? [""];
  return decimals;
};

export const usePoolUnderlyingAddress = (address: string): string => {
  const [decimals] = useContractCall({
    abi: new utils.Interface(liquidityPool.abi),
    address,
    method: "getUnderlying",
    args: [],
  }) ?? [""];
  return decimals;
};

export const usePoolName = (address: string): string => {
  const [decimals] = useContractCall({
    abi: new utils.Interface(liquidityPool.abi),
    address,
    method: "name",
    args: [],
  }) ?? ["----"];
  return decimals;
};

export const usePoolVaultAddress = (address: string): string => {
  const [vaultAddress] = useContractCall({
    abi: new utils.Interface(liquidityPool.abi),
    address,
    method: "vault",
    args: [],
  }) ?? [""];
  return vaultAddress;
};

export const usePoolExchangeRate = (address: string): ScaledNumber => {
  const [exchangeRate] = useContractCall({
    abi: new utils.Interface(liquidityPool.abi),
    address,
    method: "exchangeRate",
    args: [],
  }) ?? [null];
  return new ScaledNumber(exchangeRate);
};

export const usePoolRequiredReserveRatio = (address: string): ScaledNumber => {
  const [requiredReserveRatio] = useContractCall({
    abi: new utils.Interface(liquidityPool.abi),
    address,
    method: "requiredReserves",
    args: [],
  }) ?? [null];
  return new ScaledNumber(requiredReserveRatio);
};

export const usePoolMaxReserveDeviationRatio = (
  address: string
): ScaledNumber => {
  const [maxReserveDeviationRatio] = useContractCall({
    abi: new utils.Interface(liquidityPool.abi),
    address,
    method: "reserveDeviation",
    args: [],
  }) ?? [null];
  return new ScaledNumber(maxReserveDeviationRatio);
};

export const usePool = (address: string): PoolType => {
  const lpTokenAddress = usePoolLpTokenAddress(address);
  const lpToken = useTokenInfo(lpTokenAddress);
  const underlyingAddress = usePoolUnderlyingAddress(address);
  const underlying = useTokenInfo(underlyingAddress);
  const name = usePoolName(address);
  const vaultAddress = usePoolVaultAddress(address);
  const vault = useVault(vaultAddress);
  const exchangeRate = usePoolExchangeRate(address);
  const requiredReserveRatio = usePoolRequiredReserveRatio(address);
  const maxReserveDeviationRatio = usePoolMaxReserveDeviationRatio(address);
  return {
    address,
    lpToken,
    name,
    underlying,
    vault,
    exchangeRate,
    requiredReserveRatio,
    maxReserveDeviationRatio,
  };
};
