import styled from "styled-components";
import { usePoolAddresses } from "../../contracts/poolViews";
import { Header1 } from "../../styles/Headers";
import Pool from "./Pool";

const StyledPoolsPage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  font-size: 2.3rem;
  padding: 3rem;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75rem;
`;

const PoolsPage = () => {
  const poolAddresses = usePoolAddresses();

  return (
    <StyledPoolsPage>
      <Header1>pools</Header1>
      <Content>
        {poolAddresses.map((poolAddresse: string) => (
          <Pool key={poolAddresse} address={poolAddresse} />
        ))}
      </Content>
    </StyledPoolsPage>
  );
};

export default PoolsPage;
