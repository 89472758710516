import topUpAction from "@merofinance/protocol/build/contracts/TopUpAction.json";

import useContract from "../app/hooks/use-contract";
import useGlobals from "../app/hooks/use-globals";

export const useExecuteTopup = () => {
  const globals = useGlobals();

  const { state: executeTopupState, send: executeTopup } = useContract(
    globals.TOPUP_ACTION,
    topUpAction.abi,
    "execute(address,bytes32,address,bytes32)",
    "Execute"
  );
  return { executeTopupState, executeTopup };
};
