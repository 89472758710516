import { useContractCall } from "@usedapp/core";
import { utils } from "ethers";
import vault from "@merofinance/protocol/build/contracts/Vault.json";
import { TokenInfo } from "@usedapp/core/dist/esm/src/model/TokenInfo";
import { ScaledNumber } from "scaled-number";

import { StrategyType, useStrategy } from "./strategyViews";
import { useVaultReserve, VaultReserveType } from "./vaultReserveViews";
import { useTokenInfo } from "./tokenViews";

export interface VaultType {
  address: string;
  vaultReserve: VaultReserveType;
  underlying: TokenInfo | undefined;
  strategy: StrategyType;
  targetAllocation: ScaledNumber;
  bound: ScaledNumber;
  totalUnderlying: ScaledNumber;
}

export const useVaultUnderlyingAddress = (address: string): string => {
  const [underlyingAddress] = useContractCall({
    abi: new utils.Interface(vault.abi),
    address,
    method: "getUnderlying",
    args: [],
  }) ?? [""];
  return underlyingAddress;
};

export const useVaultStrategyAddress = (address: string): string => {
  const [strategy] = useContractCall({
    abi: new utils.Interface(vault.abi),
    address,
    method: "strategy",
    args: [],
  }) ?? [""];
  return strategy;
};

export const useTargetAllocation = (address: string): ScaledNumber => {
  const [targetAllocation] = useContractCall({
    abi: new utils.Interface(vault.abi),
    address,
    method: "targetAllocation",
    args: [],
  }) ?? [null];
  return new ScaledNumber(targetAllocation);
};

export const useBound = (address: string): ScaledNumber => {
  const [bound] = useContractCall({
    abi: new utils.Interface(vault.abi),
    address,
    method: "bound",
    args: [],
  }) ?? [null];
  return new ScaledNumber(bound);
};

export const useTotalUnderlying = (address: string): ScaledNumber => {
  const underlyingAddress = useVaultUnderlyingAddress(address);
  const underlying = useTokenInfo(underlyingAddress);
  const [totalUnderlying] = useContractCall({
    abi: new utils.Interface(vault.abi),
    address,
    method: "getTotalUnderlying",
    args: [],
  }) ?? [null];
  return new ScaledNumber(totalUnderlying, underlying?.decimals);
};

export const useVault = (address: string): VaultType => {
  const strategyAddress = useVaultStrategyAddress(address);
  const reserve = useVaultReserve(address);
  const underlyingAddress = useVaultUnderlyingAddress(address);
  const underlying = useTokenInfo(underlyingAddress);
  const strategy = useStrategy(strategyAddress);
  const targetAllocation = useTargetAllocation(address);
  const bound = useBound(address);
  const totalUnderlying = useTotalUnderlying(address);
  return {
    address,
    vaultReserve: reserve,
    underlying,
    strategy,
    targetAllocation,
    bound,
    totalUnderlying,
  };
};
