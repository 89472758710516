import { useState } from "react";
import styled from "styled-components";
import Button from "../../components/Button";
import Input from "../../components/Input";
import Popup from "../../components/Popup";
import { useOracleSetFeed } from "../../contracts/oracleFunctions";
import { useOracle } from "../../contracts/oracleView";
import { Header1, Header2 } from "../../styles/Headers";

const StyledOraclePage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  font-size: 2.3rem;
  padding: 3rem;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 4rem;
`;

interface ColumnProps {
  right?: boolean;
}

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props: ColumnProps) =>
    props.right ? "flex-end" : "flex-start"};

  > div > button {
    margin-bottom: 2rem;
  }
`;
const Info = styled.div`
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 0.6rem;
`;

const InputContainer = styled.div`
  width: 100%;
  margin-top: 2rem;
`;

const OraclePage = () => {
  const oracle = useOracle();
  const [asset, setAsset] = useState("");
  const [feed, setFeed] = useState("");
  const [settingFeed, setSettingFeed] = useState(false);
  const { oracleSetFeedState, oracleSetFeed } = useOracleSetFeed();

  return (
    <StyledOraclePage>
      <Header1>oracle</Header1>
      <Content>
        <Column>
          <Header2>Details</Header2>
          <Info>{`Address: ${oracle.address}`}</Info>
          <Info>{`Stale Price Delay: ${oracle.stalePriceDelay.toCryptoString()}`}</Info>
        </Column>
        <Column right>
          <Button
            medium
            primary
            loading={
              oracleSetFeedState.status === "Mining" ||
              oracleSetFeedState.status === "PendingSignature"
            }
            click={() => setSettingFeed(true)}
          >
            Set Feed
          </Button>
          <Popup
            header="Set Oracle Feed"
            show={settingFeed}
            close={() => setSettingFeed(false)}
            buttonAction={() => oracleSetFeed(asset, feed)}
            buttonText="Set Feed"
          >
            <InputContainer>
              <Input
                value={asset}
                setValue={(v: string) => setAsset(v)}
                placeholder="Asset"
              />
            </InputContainer>
            <InputContainer>
              <Input
                value={feed}
                setValue={(v: string) => setFeed(v)}
                placeholder="Feed"
              />
            </InputContainer>
          </Popup>
        </Column>
      </Content>
    </StyledOraclePage>
  );
};

export default OraclePage;
