import styled from "styled-components";
import { usePoolAddresses } from "../../contracts/poolViews";
import { Header1, Header2 } from "../../styles/Headers";
import StrategyHarvest from "./StrategyHarvest";

const StyledHomePage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding: 3rem;

  button {
    margin-bottom: 2rem;
  }
`;

const HomePage = () => {
  const poolAddresses = usePoolAddresses();

  return (
    <StyledHomePage>
      <Header1>chores</Header1>
      <Header2>strategy harvests</Header2>
      {poolAddresses.map((poolAddress: string) => (
        <StrategyHarvest key={poolAddress} poolAddress={poolAddress} />
      ))}
    </StyledHomePage>
  );
};

export default HomePage;
