import styled from "styled-components";
import chevron from "../assets/ui/chevron.svg";
import Button from "./Button";

const Container = styled.div`
  position: relative;
  width: 100%;

  margin-top: 1rem;
  @media (max-width: 600px) {
    margin-top: 0.6rem;
  }
`;

export interface ClickableRowColumnType {
  label: string;
  value: string | JSX.Element;
  large?: boolean;
}

const StyledRowOption = styled.button`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #2b293d;
  background-color: rgba(20, 17, 40, 1);
  border-radius: 1.4rem;
  padding: 1.3rem 1.4rem;
  cursor: pointer;

  transition: background-color 0.3s;
  :hover {
    background-color: #1a1438;
  }
`;

interface ColumnProps {
  hideMobile?: boolean;
  large?: boolean;
}

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: ${(props: ColumnProps) => (props.large ? "2" : "1")};

  @media (max-width: 600px) {
    display: ${(props: ColumnProps) => (props.hideMobile ? "none" : "flex")};
  }
`;

const Header = styled.div`
  font-weight: 700;
  letter-spacing: 0.2px;
  opacity: 0.6;

  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  @media (max-width: 600px) {
    font-size: 1.1rem;
    margin-bottom: 0.2rem;
  }
`;

const Value = styled.div`
  font-weight: 700;
  letter-spacing: 0.2px;
  text-align: left;

  font-size: 1.8rem;
  @media (max-width: 600px) {
    font-size: 1.4rem;
  }

  @media only percy {
    opacity: 0;
  }
`;

const Chevron = styled.img`
  height: 2.4rem;
  width: 2.4rem;
`;

const ButtonContainer = styled.div`
  position: absolute;
  right: 1rem;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface Props {
  click: () => void;
  columns: ClickableRowColumnType[];
  button?: boolean;
  loading?: boolean;
}

const ClickableRow = ({
  click,
  columns,
  button,
  loading,
}: Props): JSX.Element => {
  const getColumnValue = (value: string | JSX.Element): JSX.Element => {
    if (typeof value === "string") return <Value>{value}</Value>;
    return value;
  };

  return (
    <Container>
      <StyledRowOption
        onClick={() => {
          if (!button) click();
        }}
      >
        {columns.map((column: ClickableRowColumnType) => (
          <Column key={column.label} large={column.large}>
            {column.label && <Header>{column.label}</Header>}
            {getColumnValue(column.value)}
          </Column>
        ))}
        <Chevron src={chevron} />
      </StyledRowOption>
      {button && (
        <ButtonContainer>
          <Button loading={loading} primary click={click}>
            Execute
          </Button>
        </ButtonContainer>
      )}
    </Container>
  );
};

export default ClickableRow;
