import { Link } from "react-router-dom";
import styled from "styled-components";
import Connector from "./Connector";
import logo from "../assets/logo/logo.svg";

interface NavItemType {
  label: string;
  link: string;
}

const navItems: NavItemType[] = [
  {
    label: "Chores",
    link: "/",
  },
  {
    label: "Pools",
    link: "/pools",
  },
  {
    label: "Oracle",
    link: "/oracle",
  },
  {
    label: "Keeper",
    link: "/keeper",
  },
];

const StyledHeader = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 3rem;
`;

const Logo = styled.img`
  height: 4.8rem;
  cursor: pointer;

  @media (max-width: 600px) {
    height: 2.9rem;
  }
`;

const NavItems = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  transform: translate(-50%, -50%);
`;

const NavItem = styled(Link)`
  margin: 0 2rem;
  font-size: 1.8rem;
  opacity: 0.8;
  font-weight: 400;

  transition: all 0.3s;
  :hover {
    opacity: 1;
  }
`;

const Header = () => {
  return (
    <StyledHeader>
      <Link to="/">
        <Logo src={logo} alt="Mero logo" />
      </Link>
      <NavItems>
        {navItems.map((navItem: NavItemType) => (
          <NavItem key={navItem.link} to={navItem.link}>
            {navItem.label}
          </NavItem>
        ))}
      </NavItems>
      <Connector />
    </StyledHeader>
  );
};

export default Header;
