import styled from "styled-components";
import { useExecutablePositions } from "../../contracts/keeperViews";
import { Header1, Header2 } from "../../styles/Headers";
import KeeperRow from "./KeeperRow";

const StyledKeeperPage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding: 3rem;
  max-width: 100rem;
`;

const KeeperPage = () => {
  const executablePositions = useExecutablePositions();

  return (
    <StyledKeeperPage>
      <Header1>manual keeper</Header1>
      <Header2>executable positions</Header2>
      {executablePositions.map((position: any, index: number) => (
        <KeeperRow key={index} position={position} />
      ))}
    </StyledKeeperPage>
  );
};

export default KeeperPage;
