import { useNavigate } from "react-router-dom";
import ClickableRow from "../../components/ClickableRow";
import { usePool } from "../../contracts/poolViews";

interface Props {
  address: string;
}

const Pool = ({ address }: Props) => {
  const pool = usePool(address);
  const navigate = useNavigate();

  return (
    <ClickableRow
      click={() => navigate(`/pool/${address}`)}
      columns={[
        {
          label: "Name",
          value: pool.name,
        },
        {
          label: "Underlying",
          value: pool.underlying?.symbol || "---",
        },
        {
          label: "LP Token",
          value: pool.lpToken?.symbol || "---",
        },
      ]}
    />
  );
};

export default Pool;
