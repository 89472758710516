import { useContractCall } from "@usedapp/core";
import { utils } from "ethers";
import vault from "@merofinance/protocol/build/contracts/Vault.json";
import vaultReserve from "@merofinance/protocol/build/contracts/VaultReserve.json";

export interface VaultReserveType {
  address: string;
  isVaultWhitelisted: boolean;
}

export const useIsVaultWhitelisted = (
  vaultReserveAddress: string,
  vaultAddress: string
): boolean => {
  const [isVaultWhitelisted] = useContractCall({
    abi: new utils.Interface(vaultReserve.abi),
    address: vaultReserveAddress,
    method: "isWhitelisted",
    args: [vaultAddress],
  }) ?? [true];
  return isVaultWhitelisted;
};

export const useVaultReserveAddress = (vaultAddress: string): string => {
  const [reserveAddress] = useContractCall({
    abi: new utils.Interface(vault.abi),
    address: vaultAddress,
    method: "reserve",
    args: [],
  }) ?? [""];
  return reserveAddress;
};

export const useVaultReserve = (vaultAddress: string): VaultReserveType => {
  const reserveAddress = useVaultReserveAddress(vaultAddress);
  const isVaultWhitelisted = useIsVaultWhitelisted(
    reserveAddress,
    vaultAddress
  );

  return {
    address: reserveAddress,
    isVaultWhitelisted,
  };
};
