import styled from "styled-components";

export const Header1 = styled.h1`
  font-size: 4.5rem;
  font-weight: 600;
  margin: 0 auto;
  text-transform: capitalize;
  margin-bottom: 4rem;
`;

export const Header2 = styled.h2`
  margin-top: 3rem;
  font-size: 2.3rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
  color: var(--sub);
`;
