import { useEthers } from "@usedapp/core";
import { utils } from "ethers";

import ClickableRow from "../../components/ClickableRow";
import { useExecuteTopup } from "../../contracts/topupFunctions";
import { usePositionInfo } from "../../contracts/keeperViews";

interface Props {
  position: any;
}

const KeeperRow = ({ position }: Props) => {
  const positionInfo = usePositionInfo(position);
  const { account } = useEthers();

  const { executeTopupState, executeTopup } = useExecuteTopup();

  return (
    <ClickableRow
      button
      loading={
        executeTopupState.status === "Mining" ||
        executeTopupState.status === "PendingSignature"
      }
      click={() => {
        executeTopup(
          positionInfo.payer,
          positionInfo.account,
          account,
          position.protocol
        );
      }}
      columns={[
        {
          label: "Topup Amount",
          value: `${positionInfo.singleTopUpAmount.toCryptoString()} ${
            positionInfo.actionToken?.symbol || "---"
          }`,
        },
        {
          label: "Protocol",
          value: positionInfo.protocol,
        },
        {
          label: "Priority Fee",
          value: positionInfo.priorityFee.toCryptoString(),
        },
        {
          label: "Max Fee",
          value: positionInfo.maxFee.toCryptoString(),
        },
      ]}
    />
  );
};

export default KeeperRow;
