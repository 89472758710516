import styled from "styled-components";

const StyledFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 3rem;
`;

const Logo = styled.div`
  font-size: 2.3rem;
`;

const Footer = () => {
  return <StyledFooter />;
};

export default Footer;
