import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import PoolsPage from "./pages/pools/PoolsPage";
import HomePage from "./pages/home/HomePage";
import NotFoundPage from "./pages/not-found/NotFoundPage";
import PoolPage from "./pages/pool/PoolPage";
import OraclePage from "./pages/oracle/OraclePage";
import KeeperPage from "./pages/keeper/KeeperPage";

const Routing = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<HomePage />} />
          <Route path="pools" element={<PoolsPage />} />
          <Route path="pool/:poolAddress" element={<PoolPage />} />
          <Route path="oracle" element={<OraclePage />} />
          <Route path="keeper" element={<KeeperPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Routing;
