import { useContractCall } from "@usedapp/core";
import { utils } from "ethers";
import oracleProvider from "@merofinance/protocol/build/contracts/ChainlinkOracleProvider.json";
import { ScaledNumber } from "scaled-number";

import useGlobals from "../app/hooks/use-globals";

export interface OracleType {
  address: string;
  stalePriceDelay: ScaledNumber;
}

export const useStalePriceDelay = (): ScaledNumber => {
  const globals = useGlobals();
  const [stalePriceDelay] = useContractCall({
    abi: new utils.Interface(oracleProvider.abi),
    address: globals.CHAINLINK_ORACLE_PROVIDER,
    method: "stalePriceDelay",
    args: [],
  }) ?? [null];
  return new ScaledNumber(stalePriceDelay, 0);
};

export const useOracle = (): OracleType => {
  const globals = useGlobals();
  const stalePriceDelay = useStalePriceDelay();
  return {
    address: globals.CHAINLINK_ORACLE_PROVIDER,
    stalePriceDelay,
  };
};
