import Button from "../../components/Button";
import { usePool } from "../../contracts/poolViews";
import { useHarvest } from "../../contracts/vaultFunctions";

interface Props {
  poolAddress: string;
}

const StrategyHarvest = ({ poolAddress }: Props) => {
  const pool = usePool(poolAddress);
  const { harvestState, harvest } = useHarvest(poolAddress);

  return (
    <Button
      loading={
        harvestState.status === "Mining" ||
        harvestState.status === "PendingSignature"
      }
      medium
      primary
      click={() => harvest()}
    >{`Harvest ${pool.vault.strategy.harvestable.toCryptoString()} ${
      pool.underlying?.symbol
    } for ${pool.name} Pool`}</Button>
  );
};

export default StrategyHarvest;
