import { useContractCall } from "@usedapp/core";
import { utils } from "ethers";
import { TokenInfo } from "@usedapp/core/dist/esm/src/model/TokenInfo";
import meroTriHopCvx from "@merofinance/protocol/build/contracts/MeroTriHopCvx.json";
import meroEthCvx from "@merofinance/protocol/build/contracts/MeroEthCvx.json";
import { ScaledNumber } from "scaled-number";
import { useTokenInfo } from "./tokenViews";

export interface StrategyType {
  address: string;
  vaultAddress: string;
  underlying: TokenInfo | undefined;
  balance: ScaledNumber;
  harvestable: ScaledNumber;
  strategist: string;
  curvePool: string;
  curvePoolLpToken: TokenInfo | undefined;
  imbalanceToleranceIn: ScaledNumber;
  imbalanceToleranceOut: ScaledNumber;
}

const abi = (address: string) => {
  if (address === "0xD6c08A65669Bf0b6f826521C08Dc8C215730223c")
    return new utils.Interface(meroEthCvx.abi);
  return new utils.Interface(meroTriHopCvx.abi);
};

export const useStrategyUnderlyingAddress = (address: string): string => {
  const [underlying] = useContractCall({
    abi: abi(address),
    address,
    method: "underlying",
    args: [],
  }) ?? [""];
  return underlying;
};

export const useStrategyVaultAddress = (address: string): string => {
  console.log("Getting vault address");
  console.log(address);
  const [vaultAddress] = useContractCall({
    abi: abi(address),
    address,
    method: "vault",
    args: [],
  }) ?? [""];
  console.log(vaultAddress);
  return vaultAddress;
};

export const useStrategyBalance = (address: string): ScaledNumber => {
  const underlyingAddress = useStrategyUnderlyingAddress(address);
  const underlying = useTokenInfo(underlyingAddress);
  const [balance] = useContractCall({
    abi: abi(address),
    address,
    method: "balance",
    args: [],
  }) ?? [null];
  return new ScaledNumber(balance, underlying?.decimals);
};

const getMultiplier = (underlying: TokenInfo | undefined) => {
  const multipliers: Record<string, ScaledNumber> = {
    ETH: ScaledNumber.fromUnscaled("9.396551724"),
    DAI: ScaledNumber.fromUnscaled("1.107981221"),
    USDC: ScaledNumber.fromUnscaled("1.107981221"),
  };
  if (!underlying) return ScaledNumber.fromUnscaled(1);
  const multiplier = multipliers[underlying.symbol];
  if (!multiplier) return ScaledNumber.fromUnscaled(1);
  return multiplier;
};

export const useStrategyHarvestable = (address: string): ScaledNumber => {
  const underlyingAddress = useStrategyUnderlyingAddress(address);
  const underlying = useTokenInfo(underlyingAddress);
  const [harvestable] = useContractCall({
    abi: abi(address),
    address,
    method: "harvestable",
    args: [],
  }) ?? [null];
  const multiplier = getMultiplier(underlying);
  return new ScaledNumber(harvestable, underlying?.decimals).mul(multiplier);
};

export const useStrategyStrategist = (address: string): string => {
  const [strategist] = useContractCall({
    abi: abi(address),
    address,
    method: "strategist",
    args: [],
  }) ?? ["----"];
  return strategist;
};

export const useStrategyCurvePool = (address: string): string => {
  const [curvePool] = useContractCall({
    abi: abi(address),
    address,
    method: "curvePool",
    args: [],
  }) ?? ["----"];
  return curvePool;
};

export const useStrategyCurvePoolLpTokenAddress = (address: string): string => {
  const [lp] = useContractCall({
    abi: abi(address),
    address,
    method: "lp",
    args: [],
  }) ?? [""];
  return lp;
};

export const useStrategyImbalanceToleranceIn = (
  address: string
): ScaledNumber => {
  const [imbalanceToleranceIn] = useContractCall({
    abi: abi(address),
    address,
    method: "imbalanceToleranceIn",
    args: [],
  }) ?? [null];
  return new ScaledNumber(imbalanceToleranceIn);
};

export const useStrategyImbalanceToleranceOut = (
  address: string
): ScaledNumber => {
  const [imbalanceToleranceOut] = useContractCall({
    abi: abi(address),
    address,
    method: "imbalanceToleranceOut",
    args: [],
  }) ?? [null];
  return new ScaledNumber(imbalanceToleranceOut);
};

export const useStrategy = (address: string): StrategyType => {
  const underlyingAddress = useStrategyUnderlyingAddress(address);
  const vaultAddress = useStrategyVaultAddress(address);
  const underlying = useTokenInfo(underlyingAddress);
  const balance = useStrategyBalance(address);
  const harvestable = useStrategyHarvestable(address);
  const strategist = useStrategyStrategist(address);
  const curvePool = useStrategyCurvePool(address);
  const curvePoolLpTokenAddress = useStrategyCurvePoolLpTokenAddress(address);
  const curvePoolLpToken = useTokenInfo(curvePoolLpTokenAddress);
  const imbalanceToleranceIn = useStrategyImbalanceToleranceIn(address);
  const imbalanceToleranceOut = useStrategyImbalanceToleranceOut(address);
  return {
    address,
    vaultAddress,
    underlying,
    balance,
    harvestable,
    strategist,
    curvePool,
    curvePoolLpToken,
    imbalanceToleranceIn,
    imbalanceToleranceOut,
  };
};
