import { useState } from "react";
import Input from "./Input";
import Popup from "./Popup";

interface Props {
  header: string;
  show: boolean;
  loading: boolean;
  placeholder: string;
  close: () => void;
  action: (value: string) => void;
}

const TextInputPopup = ({
  show,
  loading,
  close,
  action,
  placeholder,
  header,
}: Props) => {
  const [value, setValue] = useState("");

  return (
    <Popup
      header={header}
      loading={loading}
      show={show}
      close={close}
      buttonText="Submit"
      buttonAction={() => action(value)}
    >
      <Input
        value={value}
        setValue={(v: string) => setValue(v)}
        placeholder={placeholder}
      />
    </Popup>
  );
};

export default TextInputPopup;
