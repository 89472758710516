import meroTriHopCvx from "@merofinance/protocol/build/contracts/MeroTriHopCvx.json";

import useContract from "../app/hooks/use-contract";
import { usePool } from "./poolViews";

export const useChangeConvexPool = (poolAddress: string) => {
  const pool = usePool(poolAddress);

  const { state: changeConvexPoolState, send: changeConvexPool } = useContract(
    pool.vault.strategy.address,
    meroTriHopCvx.abi,
    "changeConvexPool",
    "Change Convex Pool"
  );
  return { changeConvexPoolState, changeConvexPool };
};

export const useAddRewardToken = (poolAddress: string) => {
  const pool = usePool(poolAddress);

  const { state: addRewardTokenState, send: addRewardToken } = useContract(
    pool.vault.strategy.address,
    meroTriHopCvx.abi,
    "addRewardToken",
    "Add Reward Token"
  );
  return { addRewardTokenState, addRewardToken };
};

export const useSetImbalanceToleranceIn = (poolAddress: string) => {
  const pool = usePool(poolAddress);

  const { state: setImbalanceToleranceInState, send: setImbalanceToleranceIn } =
    useContract(
      pool.vault.strategy.address,
      meroTriHopCvx.abi,
      "setImbalanceToleranceIn",
      "Set Imbalance Tolerance In"
    );
  return { setImbalanceToleranceInState, setImbalanceToleranceIn };
};

export const useSetImbalanceToleranceOut = (poolAddress: string) => {
  const pool = usePool(poolAddress);

  const {
    state: setImbalanceToleranceOutState,
    send: setImbalanceToleranceOut,
  } = useContract(
    pool.vault.strategy.address,
    meroTriHopCvx.abi,
    "setImbalanceToleranceOut",
    "Set Imbalance Tolerance Out"
  );
  return { setImbalanceToleranceOutState, setImbalanceToleranceOut };
};
