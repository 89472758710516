import styled from "styled-components";
import { useState } from "react";
import { useChangeConvexPool } from "../contracts/strategyFunctions";
import Button from "./Button";
import Input from "./Input";
import Popup from "./Popup";

const InputContainer = styled.div`
  width: 100%;
  margin-top: 2rem;
`;

interface Props {
  poolAddress: string;
}

const ChangeConvexPool = ({ poolAddress }: Props) => {
  const [convexPid, setConvexPid] = useState("");
  const [curveIndex, setCurveIndex] = useState("");
  const [changingConvexPool, setChangingConvexPool] = useState(false);
  const { changeConvexPoolState, changeConvexPool } = useChangeConvexPool(
    poolAddress || ""
  );

  return (
    <>
      <Button medium primary click={() => setChangingConvexPool(true)}>
        Change Convex Pool
      </Button>
      <Popup
        header="Change Convex Pool"
        loading={
          changeConvexPoolState.status === "Mining" ||
          changeConvexPoolState.status === "PendingSignature"
        }
        show={changingConvexPool}
        close={() => setChangingConvexPool(false)}
        buttonText="Submit"
        buttonAction={() => changeConvexPool(convexPid, curveIndex)}
      >
        <InputContainer>
          <Input
            value={convexPid}
            setValue={(v: string) => setConvexPid(v)}
            placeholder="Convex PID"
          />
        </InputContainer>
        <InputContainer>
          <Input
            value={curveIndex}
            setValue={(v: string) => setCurveIndex(v)}
            placeholder="Curve Index"
          />
        </InputContainer>
      </Popup>
    </>
  );
};

export default ChangeConvexPool;
