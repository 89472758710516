import { useToken } from "@usedapp/core";
import { TokenInfo } from "@usedapp/core/dist/esm/src/model/TokenInfo";

export const useTokenInfo = (address: string): TokenInfo | undefined => {
  const token = useToken(address);
  if (address === "0x0000000000000000000000000000000000000000")
    return {
      name: "Ether",
      symbol: "ETH",
      decimals: 18,
    };
  return token;
};
