interface GlobalsType {
  ADDRESS_PROVIDER: string;
  CHAINLINK_ORACLE_PROVIDER: string;
  KEEPER_HELPER: string;
  TOPUP_ACTION: string;
}

export const ETH_GLOBALS: GlobalsType = {
  ADDRESS_PROVIDER: "0xa298d39715AE492e4CAF3Ccb33cBF57abC5238d7",
  CHAINLINK_ORACLE_PROVIDER: "0x97F3fa4BeB6536194C862F9CcAe7e77074511a6B",
  KEEPER_HELPER: "0x477533eb9bb286395de5bff85BaaAF5df8318Ff4",
  TOPUP_ACTION: "0xaA450F9bE45fe7041A2460B3122017653DDA548A",
};

export const KOVAN_GLOBALS: GlobalsType = {
  ADDRESS_PROVIDER: "0xFCf9099D09dBf9498Ad356006C95bDb988022e7E",
  CHAINLINK_ORACLE_PROVIDER: "0x29a8Fc43334bc940156D6d283E504B721082c0B6",
  KEEPER_HELPER: "0xaF5bCfd4d3695c886A78FBf7C6259963f7CAf9Dd",
  TOPUP_ACTION: "0x1e2c0Ec0D282b2c77D8468052884Df7F3116ECcc",
};

export const POLYGON_GLOBALS: GlobalsType = {
  ADDRESS_PROVIDER: "0x80FCa9d4bBD2999d7c35C03fd61fed29c6eBA88D",
  CHAINLINK_ORACLE_PROVIDER: "0x17CE9F141745cC9E9769FC5454AF34b33fBc62ba",
  KEEPER_HELPER: "0x6334f124B43E30c0f4Fb18d5D75c76f024F185E9",
  TOPUP_ACTION: "0x5572e7E556d31F48BE4f5723fe3d2BEb25E8e548",
};
