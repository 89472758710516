import vault from "@merofinance/protocol/build/contracts/Vault.json";

import useContract from "../app/hooks/use-contract";
import { usePool } from "./poolViews";

export const useHarvest = (poolAddress: string) => {
  const pool = usePool(poolAddress);

  const { state: harvestState, send: harvest } = useContract(
    pool.vault.address,
    vault.abi,
    "harvest",
    "Harvest"
  );
  return { harvestState, harvest };
};

export const useWithdrawAll = (poolAddress: string) => {
  const pool = usePool(poolAddress);

  const { state: withdrawAllState, send: withdrawAll } = useContract(
    pool.vault.address,
    vault.abi,
    "withdrawAll",
    "Withdraw All"
  );
  return { withdrawAllState, withdrawAll };
};

export const usePrepareNewStrategy = (poolAddress: string) => {
  const pool = usePool(poolAddress);

  const { state: prepareNewStrategyState, send: prepareNewStrategy } =
    useContract(
      pool.vault.address,
      vault.abi,
      "prepareNewStrategy",
      "Prepare New Strategy"
    );
  return { prepareNewStrategyState, prepareNewStrategy };
};
