import oracleProvider from "@merofinance/protocol/build/contracts/ChainlinkOracleProvider.json";

import useContract from "../app/hooks/use-contract";
import useGlobals from "../app/hooks/use-globals";

export const useOracleSetFeed = () => {
  const globals = useGlobals();
  const { state: oracleSetFeedState, send: oracleSetFeed } = useContract(
    globals.CHAINLINK_ORACLE_PROVIDER,
    oracleProvider.abi,
    "setFeed",
    "Set Oracle Feed"
  );
  return { oracleSetFeedState, oracleSetFeed };
};
